<template>
  <v-row>
    <v-col class="mx-auto" cols="12" md="12" xl="11">
      <v-card elevation="2" class="tw-px-2 md:tw-px-6 tw-mt-4 md:tw-mt-10">
        <div  v-if="isLoading"  class="tw-my-2 tw-flex tw-items-center tw-justify-center">
        <v-progress-circular
        class="tw-my-2"
                    :size="70"
                    color="primary"
                    indeterminate
                    absolute
                    center
                  ></v-progress-circular>
        </div>
        <div v-else>
        <div class="c-bg-blue tw--mt-4 tw-h-20">
          <p class="c-yellow-text tw-text-xl tw-ml-5 tw-pt-7">
            {{ getGarageDetails.name }} Report
          </p>
        </div>
        <v-card-title class="mt-4"> Supplementary Guidelines</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="">
          <p>
            Now, since you have possession of the car and the ability to conduct
            a physical assessment or disassembly, please feel free to do so. If
            needed, provide a supplementary list of required parts, and we'll
            promptly
            <span class="c-blue-text">
              review it within 24 Hrs. If not, proceed with the repair as
              outlined in the approved bid.</span
            >
          </p>
        </v-card-text>
        <v-card-text>
          <h2 class="mb-4 tw-text-lg">Client Details</h2>
          <v-divider></v-divider>
          <v-row class="mt-4">
            <v-col>
              <v-row>
                <v-col class="tw-font-bold">Assessment No:</v-col>
                <v-col>{{ getBookingId }}</v-col>
              </v-row>
            </v-col>
            <v-col>
              <v-row>
                <v-col class="tw-font-bold">Date Created:</v-col>
                <v-col>{{ this.bookingInfo.arrivedAt | formatToHuman }}</v-col>
              </v-row>
            </v-col>
            <v-col>
              <v-row>
                <v-col class="tw-font-bold">Claim Number:</v-col>
                <v-col>{{  getBidDetails.claimNumber }}</v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-row>
                <v-col class="tw-font-bold">Policy No:</v-col>
                <v-col> {{ getPolicyDetails.policyNumber }}</v-col>
              </v-row>
            </v-col>
            <v-col>
              <v-row>
                <v-col class="tw-font-bold">Insured’s Name:</v-col>
                <v-col>{{ getClientDetails.name }}</v-col>
              </v-row>
            </v-col>
            <v-col></v-col>
          </v-row>
        </v-card-text>
        <v-card-text>
          <h2 class="mb-4 tw-text-lg">Vehicle Details</h2>
          <v-divider></v-divider>
          <v-row class="mt-4">
            <v-col>
              <v-row>
                <v-col class="tw-font-bold">Registration No:</v-col>
                <v-col>{{ getVehicleDetails.registrationNumber }}</v-col>
              </v-row>
            </v-col>
            <v-col>
              <v-row>
                <v-col class="tw-font-bold">Make:</v-col>
                <v-col>{{ getBidDetails.make }}</v-col>
              </v-row>
            </v-col>
            <v-col>
              <v-row>
                <v-col class="tw-font-bold">Model:</v-col>
                <v-col>{{ getBidDetails.model }}</v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-row>
                <v-col class="tw-font-bold">Vehicle Color:</v-col>
                <v-col>{{ getBidDetails.color }}</v-col>
              </v-row>
            </v-col>
            <v-col>
              <v-row>
                <v-col class="tw-font-bold">YOM:</v-col>
                <v-col>{{ getBidDetails.yearOfManufacture }}</v-col>
              </v-row>
            </v-col>
            <v-col>
              <v-row>
                <v-col class="tw-font-bold">Vehicle Type:</v-col>
                <v-col>{{ getBidDetails.bodyType }}</v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-row>
                <v-col class="tw-font-bold">Engine No:</v-col>
                <v-col> {{ getBidDetails.engineNumber }}</v-col>
              </v-row>
            </v-col>
            <v-col>
              <v-row>
                <v-col class="tw-font-bold">Chassis No:</v-col>
                <v-col>{{ getBidDetails.chassisNumber }}</v-col>
              </v-row>
            </v-col>
            <v-col>
              <v-row>
                <v-col class="tw-font-bold">Mileage:</v-col>
                <v-col>{{ getBidDetails.mileage }}</v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text>
          <h2 class="mb-4 tw-text-lg">Repair & Supplementary Form</h2>
          <v-divider></v-divider>
          <p v-if="getStatus === 'GARAGE_SUPPLEMENTARY_TO_EXT_ASSESSOR'"  class="mt-4">
            Submit your comment on the vehicle for confirmation of the estimated
            prices of vehicle parts before repairs and quantity required, grade
            and quality of the parts as well as their prices.
            <span class="c-blue-text"
              >Please note the highlighted blue sections</span
            >
          </p>
          <p v-else class="mt-4">
            Submit your bid on the vehicle for confirmation of the estimated
            prices of vehicle parts before repairs and quantity required, grade
            and quality of the parts as well as their prices.
            <span class="c-blue-text"
              >Please use the highlighted blue sections to input your
              amount</span
            >
          </p>
        </v-card-text>
        <v-card-text>
          <v-divider></v-divider>
          <div class="tw-grid tw-grid-cols-11 tw-justify-items-start mt-4">
            <p class="c-gray-text tw-font-bold">Part</p>
            <p class="c-gray-text tw-font-bold tw-col-span-1">Part Type</p>
            <p class="c-gray-text tw-font-bold tw-col-span-1">Quantity</p>
            <p class="c-gray-text tw-font-bold">Bid Amt</p>
            <p class="c-blue-text tw-font-bold">Supp Amt</p>
            <p class="c-blue-text tw-font-bold tw-col-start-6 tw-col-end-8">
              Garage Comments
            </p>
            <p class="c-blue-text tw-font-bold tw-col-start-8 tw-col-end-10">
              Assessor Comments
            </p>
            <p class="c-blue-text tw-font-bold">Final Amt</p>
            <p class="c-gray-text tw-font-bold tw-justify-self-center">VAT</p>
          </div>
          <v-divider></v-divider>
          <v-row v-for="(part, i) in getSupplementaryList" :key="i" class="">
            <v-form v-model="isValid" ref="supplementary" :disabled="getStatus !== 'GARAGE_SUPPLEMENTARY_RECEIVED'">
            <div class="tw-grid tw-grid-cols-11 tw-items-center ml-3 mt-2">
              <div>{{ part.part }}</div>
              <div>{{ part.type }}</div>
              <div>{{ part.quantity }}</div>
              <div>{{ part.bidAmount ? part.bidAmount : 'N/A' | formatCurrency  }}</div>
              <div>
                {{
                  part.supplementaryAmount ? part.supplementaryAmount : 'N/A' | formatCurrency
                }}
              </div>
              <div class="tw-col-start-6 tw-col-end-8 pr-5">
                <v-text-field
                  readonly
                  v-model="part.garageComment"
                ></v-text-field>
              </div>
              <div class="tw-col-start-8 tw-col-end-10 pr-5">
                <v-text-field
                  v-model="part.internalAssessorComment"
                ></v-text-field>
              </div>
              <div>
                <v-text-field
                  type="number"
                  v-model="assessmentAmounts[i]"
                  @change="setAmounts(assessmentAmounts[i], i)"
                  :rules="[required('Amount')]"
                ></v-text-field>
              </div>
              <div class="tw-justify-self-center">
                <v-switch
                  v-model="vatArray[i]"
                  @click="calculateVat(i)"
                ></v-switch>
              </div>
            </div>
          </v-form>
            <v-divider></v-divider>
          </v-row>
          <div class="mt-4 mb-4">
            <v-row>
              <v-col cols="12" align="end">
                <v-row>
                  <v-col cols="10">Net Total</v-col>
                  <v-col>Kshs. {{ totalAmt | formatCurrency }}</v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col align="end">
                <v-row>
                  <v-col cols="10">VAT 16%</v-col>
                  <v-col>Kshs. {{ totalVat | formatCurrency }}</v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col align="end">
                <v-row>
                  <v-col cols="10"><span  class="tw-text-lg tw-font-bold">GRAND TOTAL</span></v-col>
                  <v-col><span class="tw-text-lg tw-font-bold"> Kshs. {{ getGrandTotal | formatCurrency}} </span></v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
          <v-divider></v-divider>
        </v-card-text>
        <v-card-text v-if="getStatus !== 'GARAGE_SUPPLEMENTARY_RECEIVED'">
          <h2 class="mb-4 tw-text-lg">External Assesor Comments</h2>
          <v-textarea solo placeholder="E.g Renew the listed parts"  
          :disabled = "getStatus !== 'GARAGE_SUPPLEMENTARY_TO_EXT_ASSESSOR'" 
          v-model="extComments"
          :rules="[required('Comment')]"
          ></v-textarea>
        </v-card-text>
        <v-card-text>
          <v-checkbox
            v-if="getStatus !== 'GARAGE_SUPPLEMENTARY_TO_EXT_ASSESSOR'"
            v-model="checkbox"
            label="I acknowledge that the decision regarding garage parts supplementary is conclusive and has been mutually accepted. 
            I understand that this decision may impact our project and is not subject to further changes."
          ></v-checkbox>
        </v-card-text>
        <v-card-text>
          <v-row>
            <v-col cols="3">
              <p class="c-blue-text">Assessor's Signature</p>
              <p>{{ getBidDetails.assessorName }}</p>
            </v-col>
            <v-col cols="3">
              <p class="c-blue-text">Repairer's Signature</p>
              <p>{{ getGarageDetails.contact_person }}</p>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col align="end" class="my-2">
              <div v-if="getStatus !== 'GARAGE_SUPPLEMENTARY_TO_EXT_ASSESSOR'">
              <v-btn class="mr-4" color="warning" :disabled="!isValid || getStatus === 'GARAGE_SUPPLEMENTARY_TO_INT_ASSESSOR' || getStatus === 'GARAGE_SUPPLEMENTARY_DONE' " @click="sendToExtAssessor"  >Send to Ext Assessor</v-btn>
              <v-btn  @click="submitChecker" color="warning" :disabled="!isValid || checkbox.length === 0"
                >Approve Supplementary</v-btn
              >
            </div>
              <v-btn v-else color="warning" :disabled="!extComments" @click="updateAssessorComments"  >Submit Comment</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'
import { formatToHuman } from '@/utils/time'
import { formatCurrency } from '@/utils/helpers'
import validations from '@/utils/Validations'

export default {
  name: 'SupplementaryForm',
  data() {
    return {
      titles: [
        'Part',
        'Part Type',
        'Quantity',
        'Bid Amount',
        'Supp Amount',
        'Comments',
        'VAT',
      ],
      isLoading: true,
      items: ['Spare Tyres', 'OEM', 81, 4200],
      garageName: null,
      garageContactPerson: null,
      switchValue: [],
      extComments: null,
      status: true,
      checkbox: [],
      garages: [],
      bookingInfo: {},
      amounts: [],
      vatRate: 16,
      vatArray: [],
      finalAmounts: [],
      assessmentAmounts: [],
      assessorComments: [],
      totalAmt: 0,
      totalVat: 0,
      ...validations,
      isValid: false
    }
  },
  computed: {
    getSupplementaryDetails() {
      return this.$store.getters['bidForm/supplementaryForm']
    },
    getStatus() {
      return this.getSupplementaryDetails.status
    },
    getSupplementaryList() {
      return this.getSupplementaryDetails.biddingForm.supplementary
    },
    getBookingId() {
      return localStorage.getItem('bookingId')
    },
    getBidDetails() {
      return this.bookingInfo.biddingTemplate
    },
    getGarageDetails() {
      const garageId = this.getSupplementaryDetails.garageId
      let garageDetails = ''
      this.garages.map((item) => {
        if (item.id === garageId) {
          garageDetails = item
        }
      })
      return garageDetails
    },
    getClientDetails() {
      return this.bookingInfo.clientInfo
    },
    getPolicyDetails() {
      return this.bookingInfo.policyInfo
    },
    getVehicleDetails() {
      return this.bookingInfo.vehicleInfo
    },
    getGrandTotal() {
      return this.totalAmt + this.totalVat
    },
  },
  methods: {
    sendToExtAssessor() {
      const payload = {
        garageId: this.getSupplementaryDetails.garageId,
        bookingId: this.getSupplementaryDetails.bookingId,
        supplementary: [...this.getSupplementaryList],
        externalAssessorAdditionalInfo: ''
      }
      axios
        .post('/motor-assessment/api/garages/supplementary/INTERNAL_ASSESSOR/update-supplementary',payload)
        .then((res) => {
          if(res.data.success) {
            this.$store.commit('SNACKBAR', {
            state: 'success',
            message: res.data.msg,
            variant: 'success',
          })
          this.$router.go(-1)
          }
        })
        .catch((err) => {
          this.$store.commit('SNACKBAR', {
            state: 'failed',
            message: err.response.data.msg,
            variant: 'error',
          })
        })
    },
    async updateAssessorComments() {
      const payload = {
        garageId: this.getSupplementaryDetails.garageId,
        bookingId: this.getSupplementaryDetails.bookingId,
        supplementary: [...this.getSupplementaryList],
        externalAssessorAdditionalInfo: this.extComments ?? ''
      }
      await axios
        .post('/motor-assessment/api/garages/supplementary/EXTERNAL_ASSESSOR/update-supplementary',payload)
        .then((res) => {
          if(res.data.success) {
            this.$store.commit('SNACKBAR', {
            state: 'success',
            message: res.data.msg,
            variant: 'success',
          })
          this.$router.go(-1)
          }
        })
        .catch((err) => {
          this.$store.commit('SNACKBAR', {
            state: 'failed',
            message: err.response.data.msg,
            variant: 'error',
          })
        })
    },
    async submitChecker() {
      if(this.getStatus === 'GARAGE_SUPPLEMENTARY_RECEIVED' ) {
        await this.updateAssessorComments()
        setTimeout(() => {
           this.submitToGarage()
        }, 2000);
      } else {
        await this.submitToGarage()
      }
    },
    async submitToGarage(){
      if(this.getStatus === 'GARAGE_SUPPLEMENTARY_RECEIVED' ) {
        this.updateAssessorComments()
      }
      await axios
        .post(`/motor-assessment/api/garages/supplementary/${this.getSupplementaryDetails.garageId}/${this.getSupplementaryDetails.bookingId}/submit-supplementary`)
        .then((res) => {
          if(res.data.success) {
            this.$store.commit('SNACKBAR', {
            state: 'success',
            message: res.data.msg,
            variant: 'success',
          })
          this.$router.go(-1)
          }
        })
        .catch((err) => {
          this.$store.commit('SNACKBAR', {
            state: 'failed',
            message: err.response.data.msg,
            variant: 'error',
          })
        })
    },
    resetPayloadVat() {
      this.getSupplementaryList.forEach((item) => {
        item.vat = false
      })
    },
    getAmountsTotal() {
      let total = this.finalAmounts.reduce((acc, curr) => {
        return acc + (+curr.amount || 0)
      }, 0)
      this.totalAmt = total
    },
    getVatTotal() {
      let total = this.finalAmounts.reduce((acc, curr) => {
        return acc + (+curr.vat || 0)
      }, 0)
      this.totalVat = total
    },
    setAmounts(value, index) {
      if (!this.finalAmounts[index]) {
        this.getSupplementaryList[index].assessmentAmount = Math.round(value).toString()
        this.finalAmounts[index] = {
          amount: Math.round(value),
          vat: false,
          totalAmt: value,
        }
      } else {
        this.finalAmounts[index].vat = false
        this.finalAmounts[index].totalAmt = value
        this.vatArray[index] = false
        this.finalAmounts[index].amount = Math.round(value)
        this.getSupplementaryList[index].assessmentAmount = Math.round(value).toString()
        this.calculateVat(index)
      }
      this.getAmountsTotal()
    },
    calculateVat(index) {
      let finalAmt
      let isVat = this.vatArray.map((item, i) => {
        if (i === index) {
          return item
        }
      })
      finalAmt = this.getSupplementaryList.map((item, i) => {
        if (i === index) {
          return item.assessmentAmount
        }
      })
      if (isVat[index] === true && finalAmt !== '') {
        const vatAmt = Math.round(this.getVat(this.finalAmounts[index].amount))
        this.finalAmounts[index].vat = vatAmt.toString()
        this.finalAmounts[index].totalAmt =
          +this.finalAmounts[index].amount + +vatAmt
         this.assessmentAmounts[index] = Math.round(this.finalAmounts[index].totalAmt).toString()
        this.getSupplementaryList[index].vat = true
        this.getVatTotal()
      } else if (isVat[index] === false && finalAmt !== '') {
        this.finalAmounts[index].totalAmt -= this.finalAmounts[index].vat
        this.getSupplementaryList[index].vat = false
         this.assessmentAmounts[index] = Math.round(
          this.finalAmounts[index].totalAmt).toString()
        this.finalAmounts[index].vat = 0
        this.getVatTotal()
      }
      return finalAmt
    },
    getVat(amount) {
      let vatAmt = amount * (this.vatRate / 100)
      return vatAmt
    },
    async fetchGarages() {
      await axios
        .get('/motor-assessment/api/garages')
        .then((res) => {
          this.garages = res.data.data
        })
        .catch((err) => {
          this.$store.commit('SNACKBAR', {
            state: 'failed',
            message: err.response.data.msg,
            variant: 'error',
          })
        })
    },
   getBooking: function () {
       axios
        .get(`/motor-assessment/api/booking/${this.getBookingId}`)
        .then((res) => {
          if (res.data.success && res.data.data !== null) {
            this.bookingInfo = res.data.data
          }
        })
    },
    async viewform () {
      this.isLoading = true
      this.getSupplementaryList.forEach((item) => {
        this.assessmentAmounts.push(item.assessmentAmount)
        this.finalAmounts.push({
          amount: item.assessmentAmount,
          vat: item.vat ? this.getVat(item.assessmentAmount) : 0,
          totalAmt: item.assessmentAmount
        })
        this.vatArray.push(item.vat)
      })
      this.extComments = this.getStatus === 'GARAGE_SUPPLEMENTARY_TO_EXT_ASSESSOR' ? '' : this.getSupplementaryDetails.biddingForm.additionalInformation
      await this.getAmountsTotal()
      await this.getVatTotal()
      this.isLoading = false
    },
  },
  async beforeMount() {
  try {
    // Fetch garages and get booking information
    await this.fetchGarages();
    await this.getBooking();
    
    // Check status and execute further logic
    if (this.getStatus !== 'GARAGE_SUPPLEMENTARY_RECEIVED') {
      this.viewform();
    } else {
      this.resetPayloadVat();
      this.isLoading = false;
    }
  } catch (err) {
    // Handle error if needed
    this.$store.commit('SNACKBAR', {
            state: 'failed',
            message: err.response.data.msg,
            variant: 'error',
          })
  }
},


  filters: {
    formatToHuman,
    formatCurrency
  },
}
</script>
